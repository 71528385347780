// import React, { useContext } from "react";
// import DataContext from "../../config/dataContext";
// import { useHistory, Link } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
// import "./subNavLinks.scss";

// const SubNavLinks = () => {
//   const data = useContext(DataContext);
//   const history = useHistory();

//   const handleSubNav = (link) => {
//     data.handleScrollToTop()
//     data.setIsMenuActive(false)
//     if (link.isActive === true) {
//       let updatedLinks = data.bibliographyLinks.map((el) => {
//         return {...el, isActive: false}
//       })
//       data.setBibliographyLinks(updatedLinks)
//       history.push("/biography")
//     } else {
//       let updatedLinks = data.bibliographyLinks.map((el) => {
//         if (el.name === link.name) {
//           return {...el, isActive: true}
//         }
//         return {...el, isActive: false}
//       })
//       data.setBibliographyLinks(updatedLinks)
//     }
//   }

//   return (
//     <div className="container__subNavLink">
//       <ul className="subNavLinks--items">
//         {data.bibliographyLinks.map((link) => 
//           <li
//             key={uuidv4()}
//             className={link.isActive ? "item active" : "item"}
//             onClick={() => handleSubNav(link)}
//           >
//             <Link
//               to={link.slug}
//               className="small__font t--CAPS"
//             >
//               {link.name}
//             </Link>
//           </li>
//         )}
//       </ul>
//     </div>
//   );
// };

// export default SubNavLinks;
